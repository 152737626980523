<template>
    <div class="expansion-panel">
        <div class="input-group d-flex align-center" v-if="store.dualMobilityVisible">
            <group-label>Dual Mobility</group-label>
            <v-switch
                class="ml-5"
                id="dual-mobility"
                density="comfortable"
                v-model="store.dualMobility"
                color="primary"
                hide-details
                :disabled="store.dualMobilityEnabled"
            />
        </div>

        <div class="input-group">
            <div style="display: flex; justify-content: space-between; align-items: center">
                <group-label>Size</group-label>
                <div class="text-paragraph-sm">{{ store.selectedCupSize }}</div>
            </div>
            <tick-slider
                v-model="store.selectedCupIndex"
                :tick-count="store.availableCups.length"
            />
        </div>

        <div class="input-group">
            <group-label>Anteversion (degrees)</group-label>
            <planner-slider v-model="store.anteversion" :min="-5" :max="45" />
        </div>

        <div class="input-group">
            <group-label>Inclination (degrees)</group-label>
            <planner-slider v-model="store.inclination" :min="0" :max="60" />
        </div>

        <div class="input-group">
            <group-label>Depth (mm)</group-label>
            <planner-slider
                v-model="store.depthOffset"
                :min="-20"
                :max="20"
                :step="positionSliderStep"
            />
        </div>

        <div class="input-group">
            <group-label>Anterior - Posterior (mm)</group-label>
            <planner-slider
                v-model="store.apOffset"
                :min="-20"
                :max="20"
                :step="positionSliderStep"
            />
        </div>

        <div class="input-group">
            <group-label>Superior - Inferior (mm)</group-label>
            <planner-slider
                v-model="store.siOffset"
                :min="-20"
                :max="20"
                :step="positionSliderStep"
            />
        </div>

        <div class="actions">
            <app-button-secondary class="w-100" @click="planner.resetCup" :disabled="isResetCupDisabled"
                >Reset
            </app-button-secondary>
        </div>

        <SpinopelvicPanel v-if="hipSpine.isEnabled" />
    </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
    .expansion-panel :deep(.v-expansion-panel-title),
    .expansion-panel :deep(.v-expansion-panel-text__wrapper) {
        padding: 0;
    }

    .input-group {
        margin-bottom: 8px;
    }

    span {
        font-size: small;
    }

    .actions {
        display: flex;
        justify-content: end;
        margin-top: 20px;
    }
</style>

<script setup lang="ts">
    import TickSlider from '@/planner/components/TickSlider.vue';
    import PlannerSlider from '@/planner/components/PlannerSlider.vue';
    import { useCupOverlayStore } from '@/planner/cupOverlayStore';
    import { usePlannerStore } from '@/planner/plannerStore';
    import SpinopelvicPanel from '@/planner/components/spinopelvic/SpinopelvicPanel.vue';
    import { useSpinopelvic } from '@/spinopelvic/store/store';
    import {
        formatTemplateCupDifferences,
        templateCupPropertiesAreEqual,
    } from '@/planner/template/templateComparison';
    import { taggedLogger } from '@/util';
    import { computed } from 'vue';
    import { syncTemplate } from '@/planner/template/manualTemplateState';
    import GroupLabel from '@/planner/components/GroupLabel.vue';

    const log = taggedLogger('cup-panel');

    const planner = usePlannerStore();
    const store = useCupOverlayStore();
    const hipSpine = useSpinopelvic();

    const positionSliderStep = 0.5 as const;

    const isResetCupDisabled = computed(() => {
        if (!planner.case?.autoTemplate || !planner.template) {
            return true;
        }
        const autoTemplate = planner.case.autoTemplate;
        const current = syncTemplate(planner.template);
        if (templateCupPropertiesAreEqual(current, autoTemplate)) {
            return true;
        } else {
            const error = [
                'Cup changes to automated surgical template are:',
                ...formatTemplateCupDifferences(current, autoTemplate),
            ].join('\n  ');
            log.debug(`Reset enabled: ${error}`);
            return false;
        }
    });
</script>
