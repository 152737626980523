<template>
    <div class="approve-plan pb-0 mt-4">
        <app-button-cta
            class="w-100"
            prepend-icon="mdi-check"
            :loading="sync.isSaving"
            :disabled="disableApproveButton"
            @click="store.approvePlan"
        >
            Approve & Generate Plan
        </app-button-cta>
        <app-tooltip
            v-if="!userRoleCanApprovePlan"
            activator="parent"
            location="top"
        >
            <span>You do not have permission to approve this case. </span>
        </app-tooltip>
        <app-tooltip v-else-if="!valid" activator="parent" location="top">
            <span>There are incomplete changes. </span>
        </app-tooltip>
        <app-tooltip v-else-if="!changesNotApprovedYet" activator="parent" location="top">
            <span>There are no changes to approve. </span>
        </app-tooltip>
        <app-hint class="pt-2 d-flex justify-start" v-if="changesNotApprovedYet">
            <app-text inline>Changes have been made since Plan approved.</app-text>
            <app-tooltip
                max-width="450"
                activator="parent"
                location="bottom"
                open-on-hover
            >
                <v-container outline class="rounded-sm pa-0 ma-0">
                    <p class="custom-grey-body--text font-weight-bold">
                        Changes have been made since a Manual Plan was approved
                    </p>
                    <p>
                        If you wish these changes to be represented in the downloadable manual Plan
                        for this Case, click the &rsquo;Approve&rsquo; button once you&rsquo;re
                        happy with your changes.
                    </p>
                </v-container>
            </app-tooltip>
        </app-hint>
    </div>
</template>

<script setup lang="ts">
    import AppText from '@/components/AppText.vue';
    import AppHint from '@/components/AppHint.vue';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { useTemplateSyncStore } from '@/planner/template/templateSyncStore';
    import { computed, ref, watch } from 'vue';
    import { useUserStore } from '@/app/userStore/store';
    import {
        formatTemplateDifferences,
        templatesAreEqual,
    } from '@/planner/template/templateComparison';
    import { syncTemplate } from '@/planner/template/manualTemplateState';
    import { taggedLogger } from '@/util';

    const logger = taggedLogger('logger');

    const store = usePlannerStore();
    const user = useUserStore();
    const sync = useTemplateSyncStore();

    const props = withDefaults(
        defineProps<{
            valid?: boolean;
        }>(),
        {
            valid: true,
        },
    );

    const currentChangesNotApprovedYet = ref(false);

    watch(
        () => sync.hasUpdate,
        (newValue, oldValue) => {
            // after a change has been made, shows the warning
            if (oldValue && !newValue) {
                currentChangesNotApprovedYet.value = true;
            }
        },
    );

    watch(
        () => store.currentOperation,
        (newValue, oldValue) => {
            // after an app
            if (oldValue === 'approve-plan' && newValue === null) {
                currentChangesNotApprovedYet.value = false;
            }
        },
    );

    /**
     * Check if there are changes that have not been approved yet.
     * This check refers to data loaded when the page loads.
     */
    const oldChangesNotApprovedYet = computed(() => {
        if (store.case !== null) {
            const hasEverBeenApproved = store.case.manualTemplateLastApprovedUrl !== null;
            if (hasEverBeenApproved) {
                return (
                    store.case.manualTemplateLastApprovedUrl !==
                    store.case.manualTemplateCanonicalUrl
                );
            } else {
                // This is the first time the case is being approved.
                // We are trying to cover the scenario where the case has changes, but the page
                // was reloaded and the changes not approved yet.
                // At the moment in the API we do not have the ability to determine
                // if the case is dirty or not
                // The best we can do is to check is to compare its current state with
                // the state of the automated template.
                if (store.template) {
                    const comparableTemplate = syncTemplate(store.template);
                    const templatesEqual = templatesAreEqual(
                        comparableTemplate,
                        store.case.autoTemplate,
                    );

                    if (templatesEqual) {
                        logger.info(
                            `Templates (manual and auto) are equal: no need to approve::
                             ${formatTemplateDifferences(comparableTemplate, store.case.autoTemplate)}.`,
                        );
                    } else {
                        logger.info(
                            `Templates (manual and auto) are not equal: needs to approve::
                             ${formatTemplateDifferences(comparableTemplate, store.case.autoTemplate)}.`,
                        );
                        return true;
                    }
                }
            }
        }

        return false;
    });

    const emit = defineEmits(['changes-not-approved-yet']);

    /**
     * The warning is displayed, if we detect that there are changes that have not been approved yet.
     * These changes can be due to multiple reasons, such as changes previously made and detected on load,
     * or changes edited by the user in the current interaction.
     */
    const changesNotApprovedYet = computed(() => {
        return currentChangesNotApprovedYet.value || oldChangesNotApprovedYet.value;
    });

    watch(changesNotApprovedYet, (newValue) => {
        if (newValue) {
            emit('changes-not-approved-yet', newValue);
        }
    });

    /**
     * Checks if the user possesses the roles required to approve a plan.
     *
     * This is a preliminary role check intended to enhance the user experience by enabling quick validation
     * of whether a user has the basic authority to approve plans. It does not perform a comprehensive permissions check.
     *
     * The API will conduct a thorough permission validation when determining if a user can approve a specific case,
     * based on more granular conditions like case-specific roles or permissions.
     *
     * Given that the planner is only accessible to users already granted access to the case,
     * this lightweight role check provides a fast and sufficient safeguard at the UI level.
     */
    const userRoleCanApprovePlan = computed(() => {
        return user.isAdmin || user.isOrgAdmin || user.isSurgeon;
    });

    const disableApproveButton = computed(() => {
        // Early return if planner mode is disabled as the button should always be disabled
        if (store.plannerMode === 'disabled') {
            return true;
        }

        // Early return if the user does not have the role to approve plans as the button should always be disabled
        if (!userRoleCanApprovePlan.value) {
            return true;
        }

        // Disable the button while the changes are happening or if there are no changes to approve
        return sync.hasUpdate || !changesNotApprovedYet.value || !props.valid;
    });
</script>
