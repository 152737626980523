import { defineStore } from 'pinia';
import { ref } from 'vue';
import { type SearchResult } from '@/api/caseSearch';
import { isUserFriendlyErrorMessage } from '@/api/http';
import { pollCases } from '@/api/case/pollCases';
import { useAppErrorStore } from '@/app/appErrorStore';
import { cloneDeep } from 'lodash';

const defaultSearchResult = {
    count: 0,
    pages: {
        current: 0,
        previous: null,
        next: null,
    },
    cases: [],
};

function makeDefaultSearchResult() {
    return cloneDeep(defaultSearchResult);
}

export const useDashboardStore = defineStore('dashboard', () => {
    const appError = useAppErrorStore();

    const isReady = ref(false);
    const isLoading = ref(true);
    const error = ref('');
    const searchText = ref('');
    const searchFilter = ref('');
    const result = ref<SearchResult>(makeDefaultSearchResult());

    let cancellation: AbortController | null = null;

    async function load(nextPage = 1) {
        // Cancel any existing polling
        cancellation?.abort();
        cancellation = new AbortController();

        isLoading.value = true;
        error.value = '';

        // Start new polling
        appError.catchErrors(() => {
            return pollCases(
                searchText.value,
                nextPage,
                (response) => {
                    if (isUserFriendlyErrorMessage(response)) {
                        error.value = response;
                    } else {
                        searchFilter.value = searchText.value;
                        result.value = response;
                    }
                    isLoading.value = false;

                    if (!isReady.value) {
                        isReady.value = true;
                    }
                },
                { signal: cancellation?.signal },
            );
        });
    }

    async function nextPage() {
        if (result.value.pages.next) {
            await load(result.value.pages.next);
            window.scrollTo(0, 0);
        }
    }

    async function previousPage() {
        if (result.value.pages.previous) {
            await load(result.value.pages.previous);
            window.scrollTo(0, 0);
        }
    }

    function stop() {
        cancellation?.abort();
    }

    function reset() {
        isReady.value = false;
        isLoading.value = true;
        error.value = '';
        searchText.value = '';
        searchFilter.value = '';
        result.value = makeDefaultSearchResult();
    }

    return {
        isReady,
        isLoading,
        error,
        searchText,
        searchFilter,
        result,
        load,
        nextPage,
        previousPage,
        stop,
        reset,
    };
});
