<template>
    <div class="app-optimisation-targets">
        <div class="app-optimisation-target-content w-100">
            <group-label>
                <span>Optimization Targets</span>
            </group-label>
            <div class="app-optimisation-target-values w-100 mt-0 my-2 text-secondary-text">
                Leg length:
                <span class="text-subtitle-bold">{{
                    formatLength(store.targetAdjustments?.legLength)
                }}</span
                >, Offset:
                <span class="text-subtitle-bold">{{
                    formatLength(store.targetAdjustments?.offset)
                }}</span>
            </div>
        </div>
        <app-button-secondary
            class="w-100"
            icon="mdi-pencil"
            :disabled="editDisabled"
            @click="openDialog"
            >Edit targets
        </app-button-secondary>
        <app-modal v-model="isDialogOpen" width="600" persistent>
            <v-card>
                <app-modal-title @close="isDialogOpen = false"> Edit Targets</app-modal-title>

                <app-modal-content>
                    <div>
                        <group-label>Leg Length (mm)</group-label>
                        <planner-slider
                            v-model="selectedAdjustments.legLength"
                            :min="-20"
                            :max="20"
                        />
                    </div>
                    <div>
                        <group-label>Offset (mm)</group-label>
                        <planner-slider v-model="selectedAdjustments.offset" :min="-20" :max="20" />
                    </div>

                    <app-hint danger class="app-reranking-notification" v-if="!isDisabled">
                        Stems need to be reranked and current stem selection will be overwritten.
                    </app-hint>
                </app-modal-content>

                <app-modal-actions>
                    <app-button-secondary width="100" class="mr-5" @click="onCancel">
                        Cancel
                    </app-button-secondary>
                    <app-button-primary @click="onSave" :disabled="isDisabled"
                        >Save
                    </app-button-primary>
                </app-modal-actions>
            </v-card>
        </app-modal>
    </div>
</template>

<style scoped>
    .app-optimisation-targets {
        //display: flex;
        //justify-content: space-between;
        //align-items: end;
    }

    .app-optimisation-target,
    .app-optimisation-target-values {
        font-size: 14px;
        //font-weight: 300;
    }

    .app-optimisation-target-dialog {
        background-color: var(--planner-blue-button);
        color: var(--planner-white);
    }

    .app-reranking-notification {
        margin-top: 20px;
    }
</style>
<script setup lang="ts">
    import { computed, ref } from 'vue';
    import GroupLabel from '@/planner/components/GroupLabel.vue';
    import AppModalActions from '@/components/modal/AppModalActions.vue';
    import AppModalContent from '@/components/modal/AppModalContent.vue';
    import AppModalTitle from '@/components/modal/AppModalTitle.vue';
    import AppHint from '@/components/AppHint.vue';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { formatLength } from '@/lib/format/formatLength';
    import { assert } from '@/util';
    import type { Adjustments } from '@/planner/plannerState';
    import PlannerSlider from '@/planner/components/PlannerSlider.vue';

    const store = usePlannerStore();

    const editDisabled = computed(() => store.targetAdjustments === null);
    const isDialogOpen = ref(false);
    const selectedAdjustments = ref<Adjustments>({ legLength: 0, offset: 0 });

    function openDialog() {
        assert(store.targetAdjustments);
        selectedAdjustments.value = { ...store.targetAdjustments };
        isDialogOpen.value = true;
    }

    const isDisabled = computed(() => {
        return (
            selectedAdjustments.value.legLength === store.targetAdjustments?.legLength &&
            selectedAdjustments.value.offset === store.targetAdjustments?.offset
        );
    });

    function onCancel() {
        isDialogOpen.value = false;
    }

    async function onSave() {
        isDialogOpen.value = false;
        await store.setTargetAdjustments(selectedAdjustments.value);
    }
</script>
